<template>
  <div class="container">
    <van-tabs v-model="active" class="mb10" @click="onTab">
      <van-tab v-for="(item,index) in tabs" :key="index" :title="item.name" />
    </van-tabs>
    <van-list v-model="listLoading" :finished="listFinished" :finished-text="listFinishedText" @load="onListLoad">
      <div v-for="item in list" :key="item.order_id" class="list-item">
        <van-panel class="panel" :title="'订单编号：'+item.serial_number" :status="item.status_name">
          <div v-if="item.order_details.length">
            <router-link :to="'/order-detail?order_id='+item.order_id">
              <van-card
                :num="item.order_details[0].product_count"
                :price="item.order_details[0].product_discount_price"
                :origin-price="item.order_details[0].original_amount"
                :desc="item.order_details[0].product_spec"
                :title="item.order_details[0].product_name"
                :thumb="item.order_details[0].product_image"
              />
              <div v-if="item.order_details.length>1" class="card-load-more van-hairline--bottom">查看全部{{ item.order_details.length }}件商品</div>
            </router-link>
            <div class="panel-money">共{{ item.order_details.length }}件商品 合计：
              <span class="fz12">￥</span>
              <span class="ui-c-red fz16">{{ item.order_amount }}</span>
            </div>
          </div>
          <div v-if="item.status === 0" slot="footer" class="panel-actions">
            <div class="panel-button" @click="onNavigator({path:'/order-detail?order_id='+item.order_id})">取消订单</div>
            <div class="panel-button panel-button-danger" @click="onNavigator({path:'/order-detail?order_id='+item.order_id})">确认付款</div>
          </div>
          <div v-if="item.status === 2" slot="footer" class="panel-actions">
            <router-link :to="'/order-detail?order_id='+item.order_id">
              <div class="panel-button panel-button-danger">确认收货</div>
            </router-link>
          </div>
          <div v-if="item.status === 3" slot="footer" class="panel-actions">
            <router-link :to="'/order-detail?order_id='+item.order_id">
              <div class="panel-button panel-button-danger">评价</div>
            </router-link>
          </div>
        </van-panel>
      </div>
    </van-list>
    <!-- 右侧悬浮图标 -->
    <sidebar />
  </div>
</template>

<script>
import { Tab, Tabs, Card, Panel, List, Loading } from 'vant'
import { debounce } from '@/common/util'
// import { pay_balance } from '@/common/pay'
import Sidebar from '@/components/Sidebar'
import PageMixin from '@/mixins/page'

export default {
  name: 'OrderList',
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Card.name]: Card,
    [Panel.name]: Panel,
    [List.name]: List,
    [Loading.name]: Loading,
    Sidebar
  },
  mixins: [PageMixin],
  data() {
    return {
      active: 0,
      tabs: [
        { name: '全部', status: '' },
        { name: '待付款', status: 'unpay' },
        { name: '待发货', status: 'payed' },
        { name: '待收货', status: 'delivering' },
        { name: '待评价', status: 'received' }
      ],
      list: [],
      listLoading: false,
      listFinished: false,
      listFinishedText: '没有更多了',
      pageSize: 10,
      pageCurrent: 1
    }
  },
  created() {
    const status = this.$route.query.status === undefined ? '' : this.$route.query.status
    this.active = this.tabs.findIndex(item => item.status === status)
    // this.onPage()
  },
  methods: {
    onTab: debounce(function(index) {
      this.pageCurrent = 1
      this.onPage()
    }, 300),
    onListLoad() {
      this.onPage()
    },
    onPage() {
      // 订单状态status
      // payed 已支付待发货
      // delivering  已发货
      // unpay 未支付
      // canceled  申请退款
      // received 已签收
      // closed 订单关闭
      // refunded 已退款
      // refund 申请退款
      this.$api.order_index({ status: this.tabs[this.active].status, 'per-page': this.pageSize, page: this.pageCurrent })
        .then(res => {
          this.listLoading = false
          this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
          this.listFinished = this.list.length >= res._meta.totalCount
          this.listFinishedText = this.list.length ? '没有更多了' : '您还没有任何订单哦~'
          this.pageCurrent += 1
        })
    }
  }
}
</script>

<style lang="less" scoped>
.list-item {
  margin-bottom: 10px;
}
.card-load-more{
  font-size: 14px;
  color:#f44;
  padding:5px;
  line-height:28px;
  text-align:center;
}
.panel {
  .van-panel__header-value {
    flex: none;
  }
  &-money {
    padding: 10px 15px;
    font-size: 14px;
    color: #333;
    text-align: right;
  }
  &-actions {
    text-align: right;
  }
  &-button {
    display: inline-block;
    margin-right: 10px;
    font-size: 12px;
    padding: 4px 12px;
    border-radius: 20px;
    color: #666;
    border: 1px solid #b9b9b9;
  }
  &-button-danger {
    color: #f44;
    border: 1px solid #f44;
  }
  &-button::active {
    opacity: 0.8;
  }
}
.no-data .van-loading__spinner {
  display: none;
}
</style>

